$theme-colors: (
        "muldertraining"    : #E2211C,
        "dation"    : #57289D,
        "koelewijn"    : #0055A7,
        "keesdevaan": #7b7c81,
        "code95specialist": #0074bd,
        "leus": #e63027,
        "vertrouwen": #003d8f,
        "kempische": #016cb1,
        "westfriesland": #ff8200, // rgb(25, 70, 142)
        "eurotransportcollege": #009fe3, // rgb(21, 27, 58),
        "vanvugt": #e4312a // RGB (228,49,42)
);
