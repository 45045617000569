@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap');

@import './color.scss';

@import '../../node_modules/bootstrap/scss/bootstrap';

.transition {
  animation: show_animation 250ms ease-in-out;
}

@mixin Opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

@keyframes show_animation {
  0% {
    @include Opacity(0);
  }
  100% {
    @include Opacity(1);
  }
}

body {
  font-family: 'Quicksand', sans-serif !important;
}


.badge-rounded-left {
  border-radius: 10rem 0 0 10rem!important;
}
.badge-rounded-right {
  border-radius: 0 10rem 10rem 0!important;
}

.badge-not-rounded {
  border-radius: 0!important;
}