@import "~react-datetime/css/react-datetime.css";

.rtd {
  width: 100%!important;
}

.rdtPicker {
  box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.2);
  border-radius: calc(0.3rem - 1px);
}

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 25px solid #ffc966;
  border-bottom: 25px solid #ffc966;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  opacity: 0.7;
  bottom: 2px;
  right: 4px;
}

.rdtPicker td.rdtActive.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 25px solid #428bca;
  border-bottom: 25px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  opacity: 0;
  bottom: 2px;
  right: 4px;
}

.rdtPicker {
  margin-top: 0;
}

.react-datepicker-component > input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}