@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.woff') format('woff'),
  url('fonts/icomoon.svg') format('svg'),
  url('fonts/icomoon.ttf') format('ttf'),
  url('fonts/icomoon.eot') format('eot');
  font-weight: normal;
  font-style: normal;
}

[class^="glyphicons"],
[class*="glyphicons-"],
[class^="halflings"],
[class*="halflings-"] {
  font-family: 'icomoon' !important;
  font-size: 1.25rem;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

[class^="halflings"],
[class*="halflings-"] {
  font-size: 1rem;
}


@import "basic";
@import "halflings";
@import "filetypes";
